import { useEffect } from 'react';

export function SingleLogin() {
  const URL = import.meta.env.VITE_API_LOGIN_UNICO;

  useEffect(() => {
    window.location.href = URL;
  }, []);
  return null;
}
